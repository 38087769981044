import { StyleComponent } from '../../StyleComponent/V0.0.1/StyleComponent.js';


export var AssetTripDetailComponent = function(){
    var c = {map: null, group: null,  map_element: 'asset-trip-detail-map', vehicle: null, markers_group: null}
    
    function init(inputs){
        c.eventClick = inputs.eventClick;
        
        document.getElementById(c.map_element).style.height = (inputs.height-105) + 'px';
        document.getElementById(c.map_element).style.width = '100%';

        L.mapbox.accessToken = 'pk.eyJ1IjoiZW1hcnRpbjE4OTUiLCJhIjoiY2lsMDM1amJyMXFyb3ZvbTNiYWhpeHoyYyJ9.VYyu4-sibpiCWsLWd6HzcA';
        c.map = L.mapbox.map(c.map_element);
        c.map.addLayer(L.mapbox.styleLayer('mapbox://styles/mapbox/streets-v11'));
        c.map.setView([-33.015100,-71.549990], 9);   // latitude 40, longitude -75, zoom level 9

        c.markers_group = L.featureGroup();
        c.markers_group.addTo(c.map);

        var layers = {
               Mapa: L.mapbox.styleLayer('mapbox://styles/mapbox/streets-v11'),
               Satélite: L.mapbox.styleLayer('mapbox://styles/mapbox/satellite-v9')
         };

        layers.Mapa.addTo(c.map);
        L.control.layers(layers).addTo(c.map);

    };

    function show(inputs){
        
       let at = document.getElementById('asset-trip-detail-page');
       at.style.visibility = 'visible';
       let move = window.innerWidth;
       at.style.width = '100%';
       at.style.height = window.innerHeight;
       at.style.transform='translateX(-' + move + 'px)';
    };

    function hide(){
        let at = document.getElementById('asset-trip-detail-page');
        at.style.visibility = 'hidden';
        at.style.transform='translateX(500px)';
    };

    function render(data){
 
        draw_path(data);

        document.getElementById('asset-trip-detail__card').innerHTML = composeDetailCard(data);
        document.getElementById("asset-trip-detail__txt-header").innerHTML = data.summary.plate + ' - ' + data.summary.alias;
        show({});
    };

    function composeDetailCard(data){
        let ret = "";


        let _time_elapsed_txt = ``;
    
        if(data.summary.elapsed_time.h == 0){
            _time_elapsed_txt = `</span> ${data.summary.elapsed_time.m}<span class="atd__card-header-unit">m</span> ${data.summary.elapsed_time.s}<span class="atd__card-header-unit">s</span>`;
        }
        else{
            _time_elapsed_txt = `</span> ${data.summary.elapsed_time.h}<span class="atd__card-header-unit">h</span> ${data.summary.elapsed_time.m}<span class="atd__card-header-unit">m</span>`;
        }

        if(data.summary.type == 't'){

            ret = ` <div id="atd__card-header">
                        <div class="atd__card-header-info" id="atd__card-header-distance"><span class="mdi mdi-map-marker-path atd__card-header-icon"></span>${data.summary.distance}<span class="atd__card-header-unit">km</span></div>
                        <div class="atd__card-header-info" id="atd__card-header-time"><span class="mdi mdi-timer-outline atd__card-header-icon">${_time_elapsed_txt}</div>
                    </div>
                    <div id="atd__card-detail">
                        <div class="atd-trip-icon atd-trip-icon-closed"></div>
                        <div id="atd__card-addr-content">
                            <div class="atd__card-addr">
                                <div class="atd__card-addr_time">${data.summary.end_address.time}</div>
                                <div class="atd__card-addr_address">${data.summary.end_address.address.address}</div>
                            </div>
                            <div class="atd__card-addr">
                                <div class="atd__card-addr_time">${data.summary.start_address.time}</div>
                                <div class="atd__card-addr_address">${data.summary.start_address.address.address}</div>
                            </div>
                        </div>
                    </div>`;

        }
        else{
            ret = `     <div id="atd__card-header">
                            <div class="atd__card-header-info" id="atd__card-header-time-stop"><span class="mdi mdi-timer-outline atd__card-header-icon"></span> 3<span class="atd__card-header-unit">h</span> 52<span class="atd__card-header-unit">m</span></div>
                        </div>
                        <div id="atd__card-detail">
                            <div class="atd-trip-icon atd-trip-icon-stop"></div>
                            <div id="atd__card-addr-content">
                                <div class="atd__card-addr atd__card-addr-stop">
                                    <div class="atd__card-addr_time">12:45</div>
                                    <div class="atd__card-addr_address">Bremen 420</div>
                                </div>                        
                            </div>
                        </div>`;
        }


        return ret;
    };

    function draw_path(data){
        // clearMarkers
        // clear path
        let last = [];
        let cur = [];
        let _bounds = [];

        c.markers_group.clearLayers();

      //insert markers
      let ico_start =  StyleComponent.getStyle({event: "TRIP_START"});
      let ico_end =  StyleComponent.getStyle({event:  "TRIP_STOP"});

        L.marker([data.data[0].lat, data.data[0].long], {icon: L.icon(ico_start)})
                    .addTo(c.markers_group);

        L.marker([data.data[data.data.length - 1].lat, data.data[data.data.length - 1].long], {icon: L.icon(ico_end)})
                    .addTo(c.markers_group);

      last[0] = Number(data.data[0].lat);
      last[1] = Number(data.data[0].long);

      

      for(let i=1; i < data.data.length; i++){
            var latlng = new Array();
            // Actual
            cur[0] = Number(data.data[i].lat);
            cur[1] = Number(data.data[i].long);

            latlng = [[last[0], last[1]], [cur[0], cur[1]]];

            L.polyline(latlng, {color: getLineColor(Number(data.data[i].speed))})
                .addTo(c.markers_group);
    
            last[0] = cur[0];
            last[1] = cur[1];

            let siteLatLng = new L.LatLng(Number(data.data[i].lat),Number(data.data[i].long));
          
            _bounds.push(siteLatLng);
      }

      c.map.fitBounds(L.latLngBounds(_bounds), {Zoom: 10, padding: [50, 50]});
    
    };

    function getLineColor(sp){
        var c = '#2BB9E2';  // Azul claro por default
        
        if(sp >= 0 && sp < 10){
            c = '#f44242';
        }
        else if(sp >= 10 && sp < 50){
            c = '#f44242';
        }
        else if(sp >= 50 && sp < 100){
            c = '#2BB9E2';
        }
        else if(sp >= 100 && sp < 120){
            c = '#2BB9E2';
        }
        else if(sp >= 120){
            c = '#9741f4';
        }
        else{
            c = '#7BB9E2';
        }
            c = '#2BB9E2';
        return c;
    };

    return {init:init, show:show, hide:hide, render:render};
}();

import { StyleComponent } from '../../StyleComponent/V0.0.1/StyleComponent.js';


export var AssetDetailComponent = function(){
    var c = {map: null, 
                group: null,  
                map_element: 'asset-detail-map', 
                realtime:{marker: null, markers_group:null}, 
                visible:false, 
                id: null, 
                vehicle: {}
            }
    
    function init(inputs){

        StyleComponent.init();

        c.eventClick = inputs.eventClick;
  
        document.getElementById(c.map_element).style.height = (inputs.height-305) + 'px';
        document.getElementById(c.map_element).style.width = '100%';

        L.mapbox.accessToken = 'pk.eyJ1IjoiZW1hcnRpbjE4OTUiLCJhIjoiY2lsMDM1amJyMXFyb3ZvbTNiYWhpeHoyYyJ9.VYyu4-sibpiCWsLWd6HzcA';
        c.map = L.mapbox.map(c.map_element);
        c.map.addLayer(L.mapbox.styleLayer('mapbox://styles/mapbox/streets-v11'));
        //c.map.setView([-33.015100,-71.549990], 9);  // latitude 40, longitude -75, zoom level 9

        c.group = new L.featureGroup();

        var layers = {
            Mapa: L.mapbox.styleLayer('mapbox://styles/mapbox/streets-v11'),
            Satélite: L.mapbox.styleLayer('mapbox://styles/mapbox/satellite-v9')
         };

        layers.Mapa.addTo(c.map);
        L.control.layers(layers).addTo(c.map);

        c.realtime.markers_group = L.featureGroup();
        c.realtime.markers_group.addTo(c.map);



        let historyButton = document.getElementById('asset-detail-history');
        var historyBtnHammer = new Hammer(historyButton);
        historyBtnHammer.on('tap', function(ev) {
            c.eventClick("TRIP_LIST", {id: c.id, vehicle: c.vehicle});
        });


    };

    function show(inputs){
        
       let at = document.getElementById('asset-detail-page');
       at.style.visibility = 'visible';
       let move = window.innerWidth;
       at.style.width = '100%';
       at.style.height = window.innerHeight;
       at.style.transform='translateX(-' + move + 'px)';
    };

    function updateAsset(data){
            
        var _d = data;

        let _zoom = 11;
        // New coordinates
        var siteLatLng = new L.LatLng(_d.realtime.lat, _d.realtime.long);

        c.map.setView(siteLatLng, 15, false);
        // New icon
        var ico =  StyleComponent.getStyle({event:_d.realtime.event, head: _d.realtime.heading});
       
        // Vehicle id to update
        var vehicle_id = _d.vehicle.vehicle_id;
        // Marker to update
        var mrk = getMarkerbyId(vehicle_id, c.realtime.marker);

        // New marker
        if(mrk == null)
        {
            c.realtime.markers_group.clearLayers();

            let _label = getLabel(_d.vehicle);
            mrk = L.marker([_d.realtime.lat, _d.realtime.long], {icon: L.icon(ico), rotationAngle: ico.rotation, rotationOrigin:"center center"})
                    .bindTooltip(_label, { permanent: true, className:'leaflet-label', direction:'right', offset: L.point({x: 20, y: 0}) })
                    .addTo(c.realtime.markers_group);
        
            mrk.properties = _d.vehicle;
            mrk.on('click', function(e) {
            });
        }
        else{ // Update Marker
            mrk.setLatLng(siteLatLng).setIcon(L.icon(ico)).setRotationAngle(ico.rotation).setRotationOrigin("center center"); 
            _zoom = c.map.getZoom();
        }

        c.realtime.marker = mrk;
        c.map.setView(siteLatLng, _zoom, false);
    

    };

    function getMarkerbyId(asset_id, marker)
    {
       var ret = null;
       if (marker == null) {
           return ret;
       }

        if(marker.properties.vehicle_id == asset_id){
            ret = marker;
        }

       return ret;
    };

    function getLabel(input){
        /*
         let ret = "";
        if(MonitorComponent.label == 'alias'){
          ret = input.properties.alias;
        }
        else if(MonitorComponent.label == 'plate'){
          ret = input.properties.plate;
        }
        */
         let ret= input.plate;
        return ret;
    };


    function hide(){
        let at = document.getElementById('asset-detail-page');
        document.getElementById('asset-detail-page').style.visibility = 'hidden';
        at.style.transform='translateX(500px)';
        c.visible = false;
    };

    function render(data){

        c.id = data.vehicle.vehicle_id;
        c.vehicle = data.vehicle;
        c.visible = true;

        document.getElementById("asset-detail__txt-asset").innerHTML = data.vehicle.plate + ' - ' + data.vehicle.alias;
        document.getElementById("asset-detail__txt-event").innerHTML = data.realtime.event_name;
        let _addr = document.getElementById("asset-detail__txt-address");
        _addr.href = `https://www.google.com/maps/search/?api=1&query=${data.realtime.lat},${data.realtime.long}`;
        _addr.innerHTML = data.realtime.address.address;

        document.getElementById("asset-detail__txt-address").innerHTML = data.realtime.address.address;
        //document.getElementById("asset-detail__txt-date").innerHTML = data.realtime.device_time;
        document.getElementById("asset-detail__txt-speed").innerHTML = data.realtime.speed;
        //document.getElementById("asset-detail__txt-engine-status").innerHTML = data.realtime.engine;
        document.getElementById("asset-detail-time_since").innerHTML = data.realtime.time_since_text;

        
        renderAssetDetailList(data);


        // set attribute
        updateAsset(data);
        show();
        
    };

    function renderAssetDetailList(data){
        let _al = document.getElementById("asset-detail-comp-data__list");

        let _str = `        <div class="asset-detail-comp-data-row ad-cd-row-line">                              
                                <div class="ad-cd-row-name">Fecha GPS</div>                              
                                <div class="ad-cd-row-data" id="asset-detail__txt-date">${data.realtime.device_time}</div>                          
                            </div>                          
                            <div class="asset-detail-comp-data-row ad-cd-row-line">                              
                                <div class="ad-cd-row-name">Motor</div>                              
                                <div class="ad-cd-row-data" id="asset-detail__txt-engine-status">${data.realtime.engine}</div>                          
                            </div>
                             `;
        _al.innerHTML = _str;

        for(let i = 0; i < data.actuators.length; i++){
            if(data.actuators[i].name = "CUTOFF"){
                _al.innerHTML = _al.innerHTML + composeCutoffRow(data.actuators[i]);
               
                let ecButton = document.getElementById('asset-detail__btn-engine-cutoff');
                var ecBtnHammer = new Hammer(ecButton);
                ecBtnHammer.on('tap', function(ev) {
                    c.eventClick("ENGINE_CUTOFF", {id: c.id, vehicle: data.vehicle, actuator: data.actuators[i]});
                });
            }
        }


        return _str;
    };

    function composeCutoffRow(inputs){
       
        let _class = '';
        let _text = '';

        if(inputs.type == "BASIC_NO"){
            if(inputs.status == "ON"){
                _class = 'asset-detail__txt-engine-cutoff__active';  
            }
            else {
                _class = 'asset-detail__txt-engine-cutoff__inactive';  
            }

            _text = inputs.label;
        }
        else if (inputs.type == "BASIC_NO_NO_FEEDBACK"){
            _class = 'asset-detail__txt-engine-cutoff-command';
            _text = "Enviar comando";
        }
       

        let _str = `    <div class="asset-detail-comp-data-row ad-cd-row-line">                              
                            <div class="ad-cd-row-name">Bloqueo motor</div>                             
                            <div class="ad-cd-row-data waves-effect" id="asset-detail__btn-engine-cutoff">
                                <span id="asset-detail__txt-engine-cutoff" class="${_class}">${_text}</span> 
                                <svg style="width:24px;height:24px; float: right;" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                                </svg>
                            </div>                          
                        </div>                                                                                             `;
        return _str;
    };
    

    function isVisible(){
        return c.visible;
    }

    return {init:init, render:render, show:show, hide:hide, isVisible:isVisible};
}();
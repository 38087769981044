
import { MonitorComponent } from '../../Monitor/v2.0/monitor.js';
import { HeaderComponent } from '../../Header/v1.0/header.js';
import { AssetDetailComponent } from '../../AssetDetail/v2.0/assetdetail.js';
import { AssetTripListComponent } from '../../AssetTripList/v1.0/assettriplist.js';
import { AssetTripDetailComponent } from '../../TripDetail/v1.0/tripdetail.js';
import { LoginComponent } from '../../Login/v1.0/login.js';
import { ServiceComponent} from './service.js';
import { EngineCutoffComponent } from '../../EngineCutoff/v1.0/enginecutoff.js';
import { AssetListComponent } from '../../Monitor/v2.0/assetlist.js';
import { SuspendedPage } from '../../SuspendedPage/v1.0/suspended.js';

// Compilation Config
var _BROWSER  = false;

if(_BROWSER){
    document.addEventListener("DOMContentLoaded", function(event){
        AppComponent.init({});
    });
}
else{
    document.addEventListener("deviceready", function(event){   
        AppComponent.init({});
    });
}



window.addEventListener('popstate', function (event) {
    // Log the state data to the console
    //AppComponent.eventClickHandler('HISTORY_BACK');
    //console.log(event.null);
});

window.addEventListener('pushstate', function (event) {
	// Log the state data to the console
    AppComponent.eventClickHandler('HISTORY_FORWARD');
});



var AppComponent = function(){
    var current = {
                    width: 0,
                    height: 0,
                    isLoggedIn: false,
                    //base_url: 'http://localhost/OnlApp/mobile/',
                    base_url: 'https://app.onelogis.com/mobile/',
                    map_token:'pk.eyJ1IjoiZW1hcnRpbjE4OTUiLCJhIjoiY2lsMDM1amJyMXFyb3ZvbTNiYWhpeHoyYyJ9.VYyu4-sibpiCWsLWd6HzcA',
                    update_time: 30000,
                    vehicle_id: null,
                    asset_detail_timer: null,
                    settings: null,
                    monitor_timer: null}


    var _monitor = MonitorComponent;

    function init(){
        // Screen settings
        current.height = window.innerHeight;
        current.width = window.innerWidth;
        current.page = [];

        window.screen.orientation.lock('portrait');
        /*
        window.screen.orientation
        .lock("portrait")
        .then(
            success => console.log(success),
            failure => console.log(failure)
        );
        */

        document.getElementById('page').style.width = current.width+'px';
        document.getElementById('page').style.height = current.height+'px';

        document.addEventListener("backbutton", backButtonHandler, false);

        ServiceComponent.init({eventClick: eventClickHandler, base_url: current.base_url});
        HeaderComponent.init({eventClick: eventClickHandler});
        LoginComponent.init({eventClick: eventClickHandler, width: current.width, height:current.height});
        SuspendedPage.init({eventClick: eventClickHandler, width: current.width, height:current.height});
        MonitorComponent.init({eventClick: eventClickHandler, width: current.width, height:current.height});
        AssetDetailComponent.init({eventClick: eventClickHandler, width: current.width, height:current.height});
        EngineCutoffComponent.init({eventClick: eventClickHandler, width: current.width, height:current.height});
        AssetTripListComponent.init({eventClick: eventClickHandler});
        AssetTripDetailComponent.init({eventClick: eventClickHandler, width: current.width, height:current.height});
        
        window.onresize = function(e){
            
            current.height = window.innerHeight;
            current.width = window.innerWidth;
            document.getElementById('page').style.width = current.width+'px';
            document.getElementById('page').style.height = current.height+'px';
            console.log(current);
        }
        
        let _bck_btn = document.querySelectorAll('.onl-back-btn');
        for(let i = 0; i<_bck_btn.length; i++){
            _bck_btn[i].onclick = function(e){
                console.log(current.page);
                eventClickHandler('BACK_BTN');
            }
        }

        

        eventClickHandler('SETTINGS');
        
         
 
    };

    function backButtonHandler(){
        eventClickHandler('MONITOR');
    };



    function hidePreloader(){
        setTimeout(function(){
            //MonitorComponent.fitScreen();
            var pl = document.getElementById('preloader');
            // Reset Preloader
            pl.className += ' loader-inactive';
       },1000);
    };

    function debounce(time, callback){
        var time = time || 100; // 100 by default if no param
        var timer;
        return function(event){
            if(timer) clearTimeout(timer);
            timer = setTimeout(callback, time, event);
        };
    };

    function eventClickHandler(_event_code, _args){

        console.log(_event_code, _args);
        switch(_event_code){
    //=========================================
    //  ASSET
    //=========================================
            // ================================
            // MONITOR
            // ================================
            
            case 'SETTINGS':
                ServiceComponent.settings(_args, settingsHandler);
            break;

            case 'GET_USER_CONFIG':
                ServiceComponent.getUserConfig({}, getUserConfigHandler);       
            break;

            case 'LOGIN':
              LoginComponent.render();
              hidePreloader();
            break;

            case 'LOGOUT':
                ServiceComponent.logout({}, logoutSubmitHandler);
            break;

            case 'LOGIN_SUBMIT':
                ServiceComponent.login(_args, loginSumbitHandler);
                
            break;

            case 'USER_ACCOUNT_SUSPENDED':
                if(pushPage({module:'USER_ACCOUNT_SUSPENDED', page:'suspended-page'}) == true){
                    SuspendedPage.render(_args);
                    hidePreloader();
                }
            break;

            case 'HISTORY_BACK':
                popPage();
            break;

            case 'HISTORY_FORWARD':

            break;

            case 'BACK_BTN':
                backButtonHandler();
                
            break;

            case 'MAIN_MENU':
            break;

            case 'MONITOR':
                ServiceComponent.get_realtime_list({}, monitorRealtimeHandler);
            break;

            case 'MONITOR_UPDATE':
                ServiceComponent.get_realtime_list({}, monitorUpdateHandler);
            break;

            case 'ASSET_DETAIL':
                if(pushPage({module:'ASSET_DETAIL', page:'asset'}) == true){
                    current.vehicle_id = _args.id;
                    ServiceComponent.get_realtime_single(_args, AssetDetailhandler);
                }

            break;

            case 'ASSET_DETAIL_UPDATE':
                ServiceComponent.get_realtime_single({id: current.vehicle_id}, AssetDetailUpdateHandler);
            break;

            case 'ENGINE_CUTOFF':
                if(pushPage({module:'ENGINE_CUTOFF', page:'engine_cutoff'}) == true){
                    EngineCutoffComponent.render(_args);
                }
            break;

            case 'ENGINE_CUTOFF_ON':
                ServiceComponent.command(_args, cutoffSubmitHandler);
            break;

            case 'ENGINE_CUTOFF_OFF':
                 ServiceComponent.command(_args, cutoffSubmitHandler);
            break;

            case 'ENGINE_CUTOFF_STATUS':
                ServiceComponent.command_status(_args, cutoffStatusHandler);
            break;

            case 'TRIP_LIST':
                if(pushPage({module:'TRIP_LIST', page:'trip_list'}) == true){
                    AssetTripListComponent.show(_args);
                }
            break;

            case 'TRIP_LIST_UPDATE':
                ServiceComponent.trip_list(_args, assetTripListHandler); 
               
            break;

            case 'TRIP_DETAIL':
                if(pushPage({module:'TRIP_DETAIL', page:'trip_detail'}) == true){
                    ServiceComponent.trip_detail(_args, assetTripDetailHandler); 
                }
                
            break;
            default:
            break;
        }
        
    };

    function pushPage(inputs){
        let _page = current.page[current.page.length -1];
        if(current.page.length > 0 && _page.module == inputs.module){
            return false;
        }

        current.page.push({module: inputs.module, data:'', page:inputs.page}); 
        //window.history.pushState(inputs.page, null, `/${inputs.page}`);
        console.log("inserts", current.page[current.page.length -1]);
        return true;
        //console.log(current.page);
    };

    function popPage(){
        
        if(current.page.length > 0){
            // hide current screen
            //window.history.back();
            current.page.pop();
        }
        
        console.log("pops", current.page[current.page.length -1]);
    };

    function backButtonHandler(){
        let _page = current.page[current.page.length -1];
        
        switch(_page.module){
            case 'ASSET_DETAIL':
                AssetDetailComponent.hide();
                monitorUpdate();
            break;
            case 'TRIP_LIST':
                AssetTripListComponent.hide();
            break;
            case 'ENGINE_CUTOFF':
                EngineCutoffComponent.hide();
                eventClickHandler('ASSET_DETAIL_UPDATE', {});
            break;
            case 'MONITOR':
                AssetListComponent.hide();
            break;
            case 'TRIP_DETAIL':
                AssetTripDetailComponent.hide();
                eventClickHandler('ASSET_DETAIL_UPDATE', {});
            break;
        }
        
        popPage();
    }

    function getUserConfigHandler(data){

        if(data.success == true){
            hidePreloader();
            LoginComponent.hide();
            loadUserConfig(data.data);
            eventClickHandler('MONITOR');
        }
        else{
            switch(data.event){
                default:
                    hidePreloader();
                    LoginComponent.show();
                    eventClickHandler('LOGIN');
                break;
            }
        }

    };

    function loadUserConfig(data){
        document.getElementById("main-menu__txt-username").innerHTML = data.first_name + ' ' + data.last_name ;
        document.getElementById("main-menu__txt-company").innerHTML = data.company_name;

        current.settings = data;
    };

    function settingsHandler(data){
        
        if(data.success == true){
            //eventClickHandler('TRIP_DETAIL',{trip_id: 9452628, vehicle:{alias:'el auto', plate: "BHHR23", vehicle_id:1021} });
            loadUserConfig(data.data);
            eventClickHandler('MONITOR', {});
            LoginComponent.hide();
        }
        else{
            
            eventClickHandler('LOGIN');
        }
    };

    function loginSumbitHandler(data){
       
        if(data.success == true){
            window.localStorage.setItem("token", data.credentials.session_token);
            eventClickHandler('MONITOR');
            LoginComponent.hide();
            eventClickHandler('SETTINGS');
            //MonitorComponent.ClearAll();
        }
        else{
            alert("Clave no corresponde");
        }
    };

    function logoutSubmitHandler(data){
        if(data.success == true){
            popPage();
            HeaderComponent.closeMenu();
            LoginComponent.show();
            eventClickHandler('LOGIN');
        }
    };

    function monitorUpdate(){
        clearTimeout(current.monitor_timer);
        current.monitor_timer = setTimeout(
                                            function(){
                                                        if(current.page.length == 0 || current.page[current.page.length-1].module != "MONITOR"){
                                                            return;
                                                        }
                                                        eventClickHandler('MONITOR_UPDATE');
                                                    }, 
                                            current.update_time);
    };

    function monitorRealtimeHandler(data){
        pushPage({module:'MONITOR', page:'monitor'});
        MonitorComponent.update(data.data);
        HeaderComponent.showActionButton();
        monitorUpdate();
        hidePreloader();
        
    };

    function monitorUpdateHandler(data){
        MonitorComponent.update(data.data);   
        monitorUpdate();    
    };


    function cutoffSubmitHandler(data){
        // Actualizar estado del corte.
        EngineCutoffComponent.callbackSubmit(data);
    };

    function cutoffStatusHandler(data){
        EngineCutoffComponent.callbackStatus(data);
    }

    function AssetDetailhandler(data){
        if(!AssetDetailComponent.isVisible() && current.page[current.page.length-1].module == "ASSET_DETAIL"){
            let ad_data = data.data;
            ad_data.settings = current.settings;
            AssetDetailComponent.render(ad_data);
        }
        
        if(current.page.length == 0 || current.page[current.page.length-1].module != "ASSET_DETAIL" || current.vehicle_id != data.data.vehicle.vehicle_id){
            //AssetDetailComponent.hide();
            return;
        }
        clearTimeout(current.asset_detail_timer);
        current.asset_detail_timer = setTimeout(
                                                function(){
                                                            eventClickHandler('ASSET_DETAIL_UPDATE', {});
                                                        }, 
                                                current.update_time);
    }

    function AssetDetailUpdateHandler(data){
         
        if(current.page.length == 0 || current.page[current.page.length-1].module != "ASSET_DETAIL" || current.vehicle_id != data.data.vehicle.vehicle_id){
            return;
        }

        let ad_data = data.data;
        ad_data.settings = current.settings;
        AssetDetailComponent.render(ad_data);


        clearTimeout(current.asset_detail_timer);
        current.asset_detail_timer = setTimeout(
                                                function(){
                                                            eventClickHandler('ASSET_DETAIL_UPDATE', {});
                                                        }, 
                                                current.update_time);
    };


    function assetDetailUpdate(){

    };


    function assetTripListHandler(data){
        AssetTripListComponent.render(data.data);
    };

    function assetTripDetailHandler(data){
        if(data.success == false){
            return;
        }
        AssetTripDetailComponent.render(data);
    };

    return {init:init, debounce:debounce, eventClickHandler:eventClickHandler};
}();

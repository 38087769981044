
export var EngineCutoffComponent = function(){
    var c = {}
    
    function init(inputs){
        c.eventClick = inputs.eventClick;
        c.timeout_periods = 5;
    };

    function show(inputs){
       let at = document.getElementById('engine-cutoff-page'); 
       at.style.visibility = 'visible';
       let move = window.innerWidth;
       at.style.width = '100%';
       at.style.height = window.innerHeight;
       at.style.transform = 'translateX(-' + move + 'px)';
    };


    function hide(){
        let at = document.getElementById('engine-cutoff-page');
        document.getElementById('engine-cutoff-page').style.visibility = 'hidden';
        at.style.transform='translateX(500px)';
    };

    function render(data){
        let at = document.getElementById('engine-cutoff-page');
        at.style.top = '0px';
        c.vehicle = data.vehicle;

        if(data.actuator.type == "BASIC_NO"){
            if(data.actuator.status == "ON"){
                renderDeactivateCutoff(data);
            }
            else if(data.actuator.status == "OFF"){
                renderActivateCutoff(data);
            }     
        }
        else if(data.actuator.type == "BASIC_NO_NO_FEEDBACK"){
            renderNoFeedbackCutoff(data);
        }

        document.getElementById('engine-cutoff-header__txt-asset').innerHTML = data.vehicle.plate;

        show();
    };

    function renderActivateCutoff(data){
        let _str = `
                <div id="engine-cutoff__message">
                    <h2 style="text-align: center; font-weight: lighter;">¿Seguro que desea detener el vehículo ${data.vehicle.plate}?</h2></br>
                    <p>Esta acción puede causar un accidente a los pasajeros del vehículo o a terceros. Se recomienda realizar sólamente 
                        cuándo el vehículo se encuentra detenido.</p>
                </div>
                <div class="form-check">
                    <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="ck-confirm"
                    />
                <label class="form-check-label" for="flexCheckDefault">
                    Entiendo que ésta acción puede causar un accidente
                </label>
            </div>
            <button id="engine-cutoff__btn_confirm" class="onl-primary__button engine-cutoff__set engine-cutoff__btn waves-effect" disabled>Detener vehículo</button>`;

        document.getElementById('engine-cutoff-content').innerHTML = _str;
        let _btn = document.getElementById('engine-cutoff__btn_confirm');

        let ck_confirm = document.getElementById('ck-confirm');
        ck_confirm.onchange = function(e){
            if(ck_confirm.checked == true){
                _btn.disabled = false;
            }
            else{
                _btn.disabled = true;
            }
        }

        _btn.onclick = function(e){
            _btn.disabled = true;
            ck_confirm.disabled = true;
            document.getElementById("engine-cutoff-ab").classList.add("is-disabled");
            _btn.innerHTML = '<span class="mdi mdi-loading mdi-spin"> Deteniendo vehículo</span>';
            c.eventClick("ENGINE_CUTOFF_ON", {id: c.id, vehicle: data.vehicle, actuator: data.actuator, value: "set", cmd: "cutoff"});
        }
    };

    function renderDeactivateCutoff(data){
        let _str = `
                <div id="engine-cutoff__message">
                    <h2 style="text-align: center; font-weight: lighter;">¿Seguro que desea habilitar el vehículo ${data.vehicle.plate}?</h2></br>
                </div>
               
                <button id="engine-cutoff__btn_confirm" class="onl-primary__button engine-cutoff__reset engine-cutoff__btn waves-effect">Habilitar vehículo</button>`;

        document.getElementById('engine-cutoff-content').innerHTML = _str;
        let _btn = document.getElementById('engine-cutoff__btn_confirm');

        _btn.onclick = function(e){
            _btn.disabled = true;
            document.getElementById("engine-cutoff-ab").classList.add("is-disabled");
            _btn.innerHTML = '<span class="mdi mdi-loading mdi-spin"> Habilitando vehículo</span>';
            c.eventClick("ENGINE_CUTOFF_OFF", {id: c.id, vehicle: data.vehicle, actuator: data.actuator, value: "resume", cmd: "cutoff"});
        }
    };

    function renderNoFeedbackCutoff(data){
        let _str = `
            <div id="engine-cutoff__message">
                
            </div>

            <div id="engine-cutoff__map">

            </div>
                
            </br><h4 style="text-align: center; margin-bottom:20px;"></h4>
            <div class="engine-cutoff-action">
                
                
                <div id="engine-cutoff__btn_confirm_resume" class="engine-cutoff__btn">
                    <div class="engine-cutoff__btn-content">
                        <div class="engine-cutoff__btn-icon-content">
                            <p class="engine-cutoff__btn-icon mdi mdi-lock-open-outline"></p>
                        </div>
                        <div class="engine-cutoff__btn-label-content">
                            <p class="engine-cutoff__btn-label">Habilitar</p>
                        </div>
                    </div>
                </div>

                <div id="engine-cutoff__btn_confirm_set" class="engine-cutoff__btn">
                    <div class="engine-cutoff__btn-content">
                        <div class="engine-cutoff__btn-icon-content">
                            <p class="engine-cutoff__btn-icon mdi mdi-lock-outline"></p>
                        </div>
                        <div class="engine-cutoff__btn-label-content">
                            <p class="engine-cutoff__btn-label">Bloquear</p>
                        </div>
                    </div>
                </div>
            </div>
            
            `;
    
            document.getElementById('engine-cutoff-content').innerHTML = _str;

            document.getElementById('engine-cutoff__btn_confirm_set').onclick = function(e){
                lockComponents();
                document.getElementById('engine-cutoff__btn_confirm_set').innerHTML = `
                                                                                            <div class="engine-cutoff__btn-content">
                                                                                                <div class="engine-cutoff__btn-icon-content">
                                                                                                    <p class="engine-cutoff__btn-icon mdi mdi-loading mdi-spin"></p>
                                                                                                </div>
                                                                                                <div class="engine-cutoff__btn-label-content">
                                                                                                    <p class="engine-cutoff__btn-label">Bloquear</p>
                                                                                                </div>
                                                                                            </div>
                
                
                `;
                c.eventClick("ENGINE_CUTOFF_ON", {id: c.id, vehicle: data.vehicle, actuator: data.actuator, value: "set", cmd: "cutoff"});
            }

            document.getElementById('engine-cutoff__btn_confirm_resume').onclick = function(e){
                lockComponents();
                document.getElementById('engine-cutoff__btn_confirm_resume').innerHTML = `
                
                                                                                            <div class="engine-cutoff__btn-content">
                                                                                                <div class="engine-cutoff__btn-icon-content">
                                                                                                    <p class="engine-cutoff__btn-icon mdi mdi-loading mdi-spin"></p>
                                                                                                </div>
                                                                                                <div class="engine-cutoff__btn-label-content">
                                                                                                    <p class="engine-cutoff__btn-label">Habilitar</p>
                                                                                                </div>
                                                                                            </div>
                `;
                c.eventClick("ENGINE_CUTOFF_OFF", {id: c.id, vehicle: data.vehicle, actuator: data.actuator, value: "resume", cmd: "cutoff"});
            }

    };


    function lockComponents(){
        document.getElementById('engine-cutoff__btn_confirm_set').disabled = true;
        document.getElementById('engine-cutoff__btn_confirm_resume').disabled = true;
        document.getElementById("engine-cutoff-ab").classList.add("is-disabled");
    };

    function unlockComponents(){
        document.getElementById('engine-cutoff__btn_confirm_set').disabled = true;
        document.getElementById('engine-cutoff__btn_confirm_resume').disabled = true;
        document.getElementById("engine-cutoff-ab").classList.add("is-disabled");
    };

    function renderConfirmation(data){
        let _cmd = data.data.sys_cmd;

        let _msg = "";

        switch(_cmd){
            case "CUTOFF_SET":
                _msg = `</br></br><i class="mdi mdi-lock-outline icon-message"></i></br></br><h4 style="text-align: center; margin-bottom:20px;">Vehículo bloqueado</h4>`;
            break;

            case "CUTOFF_RESUME":
                _msg = `</br></br><i class="mdi mdi-lock-open-variant-outline icon-message"></i></br></br><h4 style="text-align: center;  margin-bottom:20px;">Vehículo habilitado</h4>`;
            break;

            default:
                break;
        }

        let _str = `<div id="engine-cutoff__message">${_msg}</div>`;

    document.getElementById('engine-cutoff-content').innerHTML = _str;
    };

    function renderPendingCommand(data){
        let _str = `
        <div id="engine-cutoff__message">
            </br></br><i class="mdi mdi-alert-outline icon-message"></i></br></br><h4 style="text-align: center;  margin-bottom:20px; color:#6c6c6c;">${data.message}</h4>
        </div>

   `;

    document.getElementById('engine-cutoff-content').innerHTML = _str;
    };

    function renderError(data){
        let _str = `
                    </br></br><i class="mdi mdi-alert-outline icon-message"></i></br></br><h4 style="text-align: center;  margin-bottom:20px; color:#6c6c6c;">${data.message}</h4>
                    `;

        document.getElementById('engine-cutoff-content').innerHTML = _str;
        document.getElementById("engine-cutoff-ab").classList.remove("is-disabled");
    };


    function callbackSubmit(data){
        if(data.success == true){
            switch(data.event){
                case "CMD_POST":
                    c.retries = 0;
                    c.eventClick("ENGINE_CUTOFF_STATUS", {cmd:"cutoff", value:"status", cmd_id: data.data["command_id"]});
                break;
                case "CMD_NOK":
                    renderError(data);
                    document.getElementById("engine-cutoff-ab").classList.remove("is-disabled");
                break;
                case "CMD_PENDING":
                    renderPendingCommand(data);
                    document.getElementById("engine-cutoff-ab").classList.remove("is-disabled");
                break;

                case "CMD_OK":
                   
                break;


            }
        }
        else{
     
        }

       
    };

    function callbackStatus(data){
        
        if(data.event == "CMD_STATUS"){
            switch(data.data.status){
                case "PENDING":
                    c.retries ++;
                    if(c.retries > c.timeout_periods){
                        renderError({message: "Tiempo de espera superado. Intente nuevamente"});
                        return;
                    }

                    setTimeout(
                        function(){
                                    c.eventClick("ENGINE_CUTOFF_STATUS", {cmd:"cutoff", value:"status", cmd_id: data.data.cmd_id});
                                }, 10000);
                break;

                case "RUNNING":
                    c.retries ++;
                    if(c.retries > c.timeout_periods){

                        renderError({message: "Tiempo de espera superado. Intente nuevamente"});
                        return;
                    }

                    setTimeout(
                        function(){
                                    c.eventClick("ENGINE_CUTOFF_STATUS", {cmd:"cutoff", value:"status", cmd_id:  data.data.cmd_id});
                                }, 10000);
                break;

                case "END":
                        // Enviar mensaje de cierre de comando
                        renderConfirmation(data);
                        document.getElementById("engine-cutoff-ab").classList.remove("is-disabled");

                break;

                case "TIMEOUT":
                                // Enviar mensaje de error de comando

                break;
            }

        }
    };

    return {init:init, render:render, show:show, hide:hide, callbackSubmit:callbackSubmit, callbackStatus:callbackStatus};
}();
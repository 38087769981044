import {AssetListComponent} from './assetlist.js';
import { StyleComponent } from '../../StyleComponent/V0.0.1/StyleComponent.js';


export var MonitorComponent = function(){
    var current = {map:null, group:null, map_element: 'map-div', realtime:{markers:[], markers_group:null}, markers_loaded:  false};

    function init(inputs){
        current.eventClick = inputs.eventClick;

        AssetListComponent.init({eventClick: current.eventClick, width: inputs.width, height: inputs.height});
        StyleComponent.init({});
        

        document.getElementById(current.map_element).style.height = inputs.height + 'px';
        // Map
        L.mapbox.accessToken = 'pk.eyJ1IjoiZW1hcnRpbjE4OTUiLCJhIjoiY2lsMDM1amJyMXFyb3ZvbTNiYWhpeHoyYyJ9.VYyu4-sibpiCWsLWd6HzcA';
        current.map = L.mapbox.map(current.map_element, null, {zoomControl:false});
        
        current.map.addLayer(L.mapbox.styleLayer('mapbox://styles/mapbox/streets-v11'));
        current.map.setView([-33.015100,-71.549990], 9);   // latitude 40, longitude -75, zoom level 9

        current.group = new L.featureGroup();

        var layers = {
            Mapa: L.mapbox.styleLayer('mapbox://styles/mapbox/streets-v11'),
            Satélite: L.mapbox.styleLayer('mapbox://styles/mapbox/satellite-v9')
         };

        layers.Mapa.addTo(current.map);
        L.control.layers(layers).addTo(current.map);
        
        

    };

    function show(){
        //MonitorService.get_realtime(render);
        //MonitorComponent.postitions = result.data.features;
        //app.map.update_positions(result.data);
        //app.assetList.loadData(result.data);
    };

    function setActive(){
        
    }

    function update(data){
        render(data);
        updateAssets(data);
    }


    function render(data){
        AssetListComponent.render(data);
    };

    function updateAssets(data){
        var _locations = data.features;
        var _markers = current.realtime.markers;

        // Si no existe capa, crear
        if(current.realtime.markers_group == null){
            current.realtime.markers_group = L.featureGroup();
            current.realtime.markers_group.addTo(current.map);
        }
        

        for(var i in _locations)
        {
            
             var l = _locations[i];
 
             // New coordinates
             var siteLatLng = new L.LatLng(l.geometry.coordinates[1], l.geometry.coordinates[0]);
             // New icon
             
             var ico =  StyleComponent.getStyle(l.properties);

             l.icon = ico;
             
             // Vehicle id to update
             var vehicle_id = l.properties.vehicle_id;
             // Marker to update
             var mrk = getMarkerbyId(vehicle_id, current.realtime.markers);
 
             // New marker
             if(mrk == null)
             {
                 let _label = getLabel(l);
                 mrk = L.marker([l.geometry.coordinates[1], l.geometry.coordinates[0]], {icon: L.icon(ico), rotationAngle:ico.rotation, rotationOrigin:"center center"}).bindTooltip(_label, { permanent: true, className:'leaflet-label', direction:'right', offset: L.point({x: 20, y: 0}) }).addTo(current.realtime.markers_group);
                
                 mrk.properties = l.properties;
 
                 mrk.on('click', function(e) {
                     // Process icon click
                     //current.eventClick('MAP_MKR_CLICK', {asset_id:e.target.properties.vehicle_id})
                     current.eventClick("ASSET_DETAIL", {id: e.target.properties.vehicle_id});
                 });
                 mrk.on('mouseover', function(e) {
   
                 });
                 mrk.on('mouseout', function (e) {
             
                 });
 
                 // Update marker array
                 _markers.push(mrk);
                

             }
             else{ // Update Marker
                mrk.setLatLng(siteLatLng).setIcon(L.icon(ico)).setRotationAngle(ico.rotation).setRotationOrigin("center center");
                //mrk.setLatLng(siteLatLng).setIcon(L.icon(ico));
             }
             
        }
        
        if(current.markers_loaded == false){
            current.map.fitBounds(current.realtime.markers_group.getBounds(), {Zoom: 10, padding: [50,50]});
            current.markers_loaded = true;
        }
        

    };

    function getMarkerbyId(asset_id, _array)
    {
       var ret = null;
       if (!Array.isArray(_array) || ! _array.length) {
           return ret;
       }

       for(var i in _array)
       {

           let vId = _array[i].properties.vehicle_id;
           if(vId == asset_id)
           {
               ret = _array[i];
           }
       }

       return ret;
    };

    function getLabel(input){
        /*
         let ret = "";
        if(MonitorComponent.label == 'alias'){
          ret = input.properties.alias;
        }
        else if(MonitorComponent.label == 'plate'){
          ret = input.properties.plate;
        }
        */
         let ret= input.properties.plate;
        return ret;
    };

    function hide(){};

    return {init:init, update:update};
}();

export var MapUtilsComponent = function(){
  var m = {imagePath: 'https://app.onelogis.com/images/alerts/'};
  function init(){

  };

  function getIcon(params){
        var ret = null;

        let e = params.event;
        switch(e)
        {
             case 'ENGINE_ON':
                 ret = {
                    iconUrl: m.imagePath + 'se_s.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-ok"
                };
            break;

            case 'ENGINE_OFF':
               ret = {
                    iconUrl: m.imagePath + 'ste_s.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-disable",
                };
            break;

            case 'PERIODIC_ON':
            ret = {
                    iconUrl: m.imagePath + 'po_s.png',
                    iconSize:     [26, 26], // size of the icon
                    iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -15], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation: parseInt(params.head),
                    panelStyle:"mon-panel-ok",
                };
                break;

            case 'PERIODIC_OFF':
                ret = {
                    iconUrl: m.imagePath + 'pa_s.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [4,-12],
                    rotation:     0,
                    panelStyle:"mon-panel-disable",
                };

            break;

            case 'vENGINE_ON':
                 ret = {
                    iconUrl: m.imagePath + 'se_s.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-ok",
                };
            break;

            case 'vENGINE_OFF':
               ret = {
                    iconUrl: m.imagePath + 'ste_s.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-disable",
                };
            break;

            case 'vPERIODIC_ON':
            ret = {
                    iconUrl: m.imagePath + 'po_s.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -15], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation: parseInt(params.head),
                    panelStyle:"mon-panel-ok",
            };
                break;

            case 'vPERIODIC_OFF':
                ret = {
                    iconUrl: m.imagePath + 'pa_s.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [4,-12],
                    rotation:     0,
                    panelStyle:"mon-panel-disable",
                };

            break;

            case 'SPEEDING':
                ret = {
                    iconUrl: m.imagePath + 'sp_s.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [4,-26],
                    rotation:     0,
                    panelStyle:"mon-panel-alert",
                };
                break;

            case 'HARSH_TURN':
                ret = {
                     iconUrl: m.imagePath + 'po_s.png',
                    iconSize:     [26, 26], // size of the icon
                    iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -15], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation: parseInt(params.head),
                    panelStyle:"mon-panel-alert",
                };
                break;

            case 'TURN':
                ret = {
                     iconUrl: m.imagePath + 'po_s.png',
                    iconSize:     [26, 26], // size of the icon
                    iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -15], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation: parseInt(params.head),
                    panelStyle:"mon-panel-ok",
                };
                break;

            case 'HARSH_DECEL':
                ret = {
                    iconUrl: m.imagePath + 'hd_s.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-alarm",
                };
                break;

            case 'HARSH_ACEL':
                ret = {
                    iconUrl: m.imagePath + 'ha_s.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-alarm",
                };
                break;

            case 'IDLE':
            ret = {
                    iconUrl: m.imagePath + 'id_s.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-alert",
                };
                break;
            case 'TRIP_START':
            ret = {
                    iconUrl: m.imagePath + 'ts_s.png',
                    iconSize:     [20, 20], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0
                };
                break;
            case 'TRIP_STOP':
            ret = {
                    iconUrl: m.imagePath + 'tsp_s.png',
                    iconSize:     [20, 20], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0
                };
                break;
            default:

                ret = {
                    iconUrl: m.imagePath + 'po_s.png',
                    iconSize:     [26, 26], // size of the icon
                    iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -15], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation: parseInt(params.head),
                    panelStyle:"mon-panel-ok",
                };
            break;

        }
        return ret;
  };

  return {init:init, getIcon:getIcon};
}();
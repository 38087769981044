

export var LoginComponent = function(){
    var cur = {};

    function init(inputs){
        cur.element = "page-login";
        cur.width = inputs.width;
        cur.height = inputs.height;
        cur.eventClick = inputs.eventClick;

        document.getElementById(cur.element).style.height = (inputs.height) + 'px';
        document.getElementById(cur.element).style.width = '100%';
        
    };

    function render(){
         // Set address
        // load html
        var p = document.getElementById("page-login");
        p.innerHTML = compose();


        var loginForm = document.getElementById("login-form");
        loginForm.addEventListener("submit", function(e){
            e.preventDefault();

            cur.eventClick("LOGIN_SUBMIT", {    user    : document.getElementById("txt-user").value,
                                                pwd     : document.getElementById("txt-pwd").value});
        });
    };

    function compose(){
        
        var ret = `<div class="login-wrapper">  
                    <div class="box">
                        <div class="login-logo" fxLayout="row"></div>
                        <div class="login-form-title">Iniciar sesión</div>
                    
                            <form id="login-form" class="example-form">

                                <div class="md-form mb-2 ">
                                    <input type="text" id="txt-user" class="form-control login-user">
                                    <label for="txt-user" class="login-txt__label">Usuario</label>
                                </div>

                                <div class="md-form mb-2 " style="margin-bottom:25px!important;">
                                    <input type="password" id="txt-pwd" class="form-control login-pwd">
                                    <label for="txt-pwd" class="login-txt__label">Contraseña</label>
                                </div>

            
                                <button class="waves-effect waves-light btn login-button" type="submit" style="margin:auto;">Iniciar sesión</button>
                            </form>
                    </div>

                </div>

                            
                <div class="menu-hider"></div>
            </div>`;
        return ret;
    

    };

    function hide(){
        document.getElementById(cur.element).style.display = 'none'; 
    };

    function show(){
        document.getElementById(cur.element).style.display = 'block'; 
    };


    return{ init:init,
            render:render,
            hide:hide,
            show:show}
}();



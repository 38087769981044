
export var ServiceComponent = function(){
   
    var _cur = {};
    function init(inputs){
      _cur.base_url = inputs.base_url;
      _cur.eventClick = inputs.eventClick;
    };

    function login(inputs, callback){
  
        $.ajax({
            type: 'post',
            url: `${_cur.base_url}system/login`,
            data: {user:inputs.user, password:inputs.pwd},
            crossDomain: true,
            dataType: 'json',
            success: function(result){
                callback(result);

            },
            error: function(xhr, status, error) { 
                //ERROR: No internet
            },
        });
    };

    function logout(inputs, callback){

        $.ajax({
            type: 'post',
            url: `${_cur.base_url}system/logout`,
            data: {},
            headers:{"Token":  window.localStorage.getItem("token")},
            crossDomain: true,
            dataType: 'json',
            success: function(result){
                callback(result);

            },
            error: function(xhr, status, error) { 
                //ERROR: No internet
            },
        });
    }; 

    function get_realtime_list(inputs, callback){

        $.ajax({
            type: 'get',
            url: `${_cur.base_url}monitor/realtime`,
            headers:{"Token":  window.localStorage.getItem("token")},
            data: {},
            crossDomain: true,
            dataType: 'json',
            timeout:20000,
            success: function(result){
                processServerResponse(result, callback);
            },
            error: function(xhr, status, error) { 
                //ERROR: No internet
                console.log(xhr, error, status);
            },
        });
    };

    function get_realtime_single(inputs, callback){
        $.ajax({
            type: 'get',
            url: `${_cur.base_url}monitor/realtime/${inputs.id}`,
            headers:{"Token":  window.localStorage.getItem("token")},
            dataType: 'json',
            success: function(result){
                processServerResponse(result, callback);
            },
            error: function(xhr, status, error) { 
                //ERROR: No internet
                console.log(xhr, error, status);
            },
        });
    };

    function trip_list(inputs, callback){
        $.ajax({
            type: 'get',
            url: `${_cur.base_url}monitor/trips/${inputs.id}/${inputs.date}`,
            headers:{"Token":  window.localStorage.getItem("token")},
            data: {},
            crossDomain: true,
            dataType: 'json',
            success: function(result){
                processServerResponse(result, callback);
            },
            error: function(xhr, status, error) { 
                //ERROR: No internet
                console.log(xhr, error, status);
            },
        });

    };

    function trip_detail(inputs, callback){
        $.ajax({
            type: 'get',
            url: `${_cur.base_url}monitor/trip_by_id/${inputs.trip_id}`,
            data: {},
            headers:{"Token":  window.localStorage.getItem("token")},
            crossDomain: true,
            dataType: 'json',
            success: function(result){
                processServerResponse(result, callback);

            },
            error: function(xhr, status, error) { 
                //ERROR: No internet
            },
        });
    };

    function settings (inputs, callback){
        $.ajax({
            type: 'get',
            url: `${_cur.base_url}system/settings`,
            data: {},
            headers:{"Token":  window.localStorage.getItem("token")},
            crossDomain: true,
            dataType: 'json',
            success: function(result){
                processServerResponse(result, callback);

            },
            error: function(xhr, status, error) { 
                //ERROR: No internet
            },
        });
    };

    function getUserConfig(inputs, callback){

        $.ajax({
            type: 'get',
            url: `${_cur.base_url}monitor/config`,
            data: {},
            crossDomain: true,
            dataType: 'json',
            success: function(result){
                processServerResponse(result, callback);

            },
            error: function(xhr, status, error) { 
                console.log(error);
                //ERROR: No internet
            },
        });

    };

    function command(inputs, callback){
    
        $.ajax({
            type: 'get',
            url: `${_cur.base_url}monitor/cmd/${inputs.cmd}/${inputs.value}/${inputs.vehicle.vehicle_id}`,
            data: {},
            headers:{"Token":  window.localStorage.getItem("token")},
            crossDomain: true,
            dataType: 'json',
            success: function(result){
                processServerResponse(result, callback);
            },
            error: function(xhr, status, error) { 
                console.log(error);
            },
        });
    
    };

    function command_status(inputs, callback){
    
        $.ajax({
            type: 'get',
            url: `${_cur.base_url}monitor/cmd/${inputs.cmd}/${inputs.value}/${inputs.cmd_id}`,
            data: {},
            headers:{"Token":  window.localStorage.getItem("token")},
            crossDomain: true,
            dataType: 'json',
            success: function(result){
                processServerResponse(result, callback);
            },
            error: function(xhr, status, error) { 
                console.log(error);
            },
        });
    
    };


    function processServerResponse(data, callback){
        console.log(data.event);
        if(data.event == "USER_NOT_LOGGED_IN"){
            _cur.eventClick("LOGIN", {});
            return;
        }

        if(data.event == "USER_ACCOUNT_SUSPENDED"){
            _cur.eventClick("USER_ACCOUNT_SUSPENDED", data);
            return;
        }

        callback(data);
    }

    return{ init:init,
            login:login,
            logout: logout,
            settings: settings,
            getUserConfig: getUserConfig,
            get_realtime_list:get_realtime_list,
            get_realtime_single: get_realtime_single,
            command : command,
            command_status: command_status,
            trip_list:trip_list,
            trip_detail:trip_detail};
}();
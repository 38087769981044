// Dependencias
//* Hammer.js

export var HeaderComponent = function(){
    var h = {buttonElement:'header-action-button'};
    function init(inputs){
        h.eventClick = inputs.eventClick;

    };

        /*
        var a = document.getElementById('ad-submenu-actual-btn');
        var hammerMenu = new Hammer(a);
        
        hammerMenu.on('tap', function(ev) {
            var event = new CustomEvent("header-actual-button", {'detail':'hola!'});
            document.dispatchEvent(event);
        });
        
         // Detalle histórico
        var h = document.getElementById('ad-submenu-viajes-btn');
        var hammerMenu = new Hammer(h);
        hammerMenu.on('tap', function(ev) {
            var event = new CustomEvent("header-trip-button", {'event':'hola!'});
            document.dispatchEvent(event);
        });
        */
         // Header Action Button
        var aButton = document.getElementById(h.buttonElement);
        var hammerMenu = new Hammer(aButton);
        hammerMenu.on('tap', function(ev) {
           openMenu();
        });

        var pageOverlay = document.getElementById('page-overlay'); 
        pageOverlay.onclick = function()
        {
            closeMenu();
        }

        let logoutButton = document.getElementById("nav-logout");
        let hummLogout = new Hammer(logoutButton);

        hummLogout.on('tap', function(ev){
            h.eventClick('LOGOUT');
        });
        

        function showActionButton(){
            document.getElementById(h.buttonElement).innerHTML = `<svg style="width:32px;height:32px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
        </svg>`;
            var backBtn = document.getElementById(h.buttonElement);
            backBtn.style.visibility = "visible";
        };

        function hideActionButton(){
            var backBtn = document.getElementById(h.buttonElement);
            backBtn.style.visibility = "hidden";
        }

        function openMenu(){
            let MainMenu = document.getElementById('main-menu');
            let Page = document.getElementById('page');

            Page.style.transform="translateX(280px)";
            Page.addEventListener('transitionend', () => {
                document.getElementById('page-overlay').style.visibility = 'visible';
                document.getElementById('page-overlay').style.opacity = '.3';
                MainMenu.setAttribute('data-main-menu-status','open');
            });
        }

        function closeMenu(){
            let MainMenu = document.getElementById('main-menu');
            let Page = document.getElementById('page');
    
            Page.style.transform="translateX(0)";
            Page.addEventListener('transitionend', () => {
                document.getElementById('page-overlay').style.visibility = 'hidden';
                document.getElementById('page-overlay').style.opacity = '0';
                MainMenu.setAttribute('data-main-menu-status','closed');
            });
        }





    return {init:init, showActionButton:showActionButton, openMenu:openMenu, closeMenu:closeMenu};
}();

/*

class HeaderComponent
{
    constructor(){
        this.height = 55;
        
        this.MenuElement = "header-menu";
        this.subMenuElement = "header-submenu";
        this.menuActionButton = "header-action-button";
        this.textElement = "header-text";
        this.currentBtn = '';

    }

    openMainMenu = function(){
        let MainMenu = document.getElementById('main-menu');
        let Page = document.getElementById('page');

        Page.style.transform="translateX(280px)";
        Page.addEventListener('transitionend', () => {
            document.getElementById('page-overlay').style.visibility = 'visible';
            document.getElementById('page-overlay').style.opacity = '.3';
            MainMenu.setAttribute('data-main-menu-status','open');
        });
    }

    closeMainMenu = function(){
        let MainMenu = document.getElementById('main-menu');
        let Page = document.getElementById('page');
    
        Page.style.transform="translateX(0)";
        Page.addEventListener('transitionend', () => {
            document.getElementById('page-overlay').style.visibility = 'hidden';
             document.getElementById('page-overlay').style.opacity = '0';
            MainMenu.setAttribute('data-main-menu-status','closed');
        });
    }

    init = function()
    {
        // Submenu init
        // Detalle tiempo real
        /*
        var a = document.getElementById('ad-submenu-actual-btn');
        var hammerMenu = new Hammer(a);
        
        hammerMenu.on('tap', function(ev) {
            var event = new CustomEvent("header-actual-button", {'detail':'hola!'});
            document.dispatchEvent(event);
        });
        
         // Detalle histórico
        var h = document.getElementById('ad-submenu-viajes-btn');
        var hammerMenu = new Hammer(h);
        hammerMenu.on('tap', function(ev) {
            var event = new CustomEvent("header-trip-button", {'event':'hola!'});
            document.dispatchEvent(event);
        });
        
         // Header Action Button
        var aButton = document.getElementById('header-action-button');
        var hammerMenu = new Hammer(aButton);
        hammerMenu.on('tap', function(ev) {
            app.header.procActionButton();
        });

        var pageOverlay = document.getElementById('page-overlay');
        
        pageOverlay.onclick = function()
        {
            app.header.closeMainMenu();
        }

    }

    procActionButton = function(){
        if(app.header.currentBtn === 'menuBtn')
        {
            this.toggleMainMenu();
        }
        else if(app.header.currentBtn === 'backBtn')
        {
        // Tengo que leer que dice el URI y tomo acción acorde.
            app.monitor.SheetPanDown();

        }
    }



    on = function(event, callback){}
    setHeaderColor = function(color){
        var m = document.getElementById(this.MenuElement);
        m.style.backgroundColor = color;
    }

    setText = function(txt, cl){
        var t = document.getElementById(this.textElement);
        t.innerHTML = txt;
    }

    clearText = function(){
        var t = document.getElementById(this.textElement);
        t.innerHTML = '';
    }

    showMenu = function(){
        var m = document.getElementById("header-menu");
        m.style.visibility = "visible";
    }

    hideMenu = function()
    {
        var m = document.getElementById("header-menu");
        m.style.visibility = "hidden";
    }
    
    hideSubMenu = function()
    {
        document.getElementById("header-submenu").classList.remove('header-submenu-visible');
    }

    showSubMenu = function()
    {
        document.getElementById("header-submenu").className += ' header-submenu-visible';
    }

    setActionButton = function(Class, CallbackFunction)
    {

    }

    showActionButton = function()
    {
        var backBtn = document.getElementById(this.menuActionButton);
        backBtn.style.visibility = "visible";
    }

    hideActionButton = function()
    {
        var backBtn = document.getElementById(this.menuActionButton);
        backBtn.style.visibility = "hidden";
    }

    setMenuBtn = function()
    {
        document.getElementById(this.menuActionButton).innerHTML = '<i class="fa fa-bars" ></i>';
        this.currentBtn = 'menuBtn';
    }

    setBackBtn = function()
    {
        document.getElementById(this.menuActionButton).innerHTML = '<i class="fa fa-angle-left" ></i>';
        this.currentBtn = 'backBtn';
        
    }

    selectButtom = function(id)
    {

        // Marca boton
        var submenu = document.getElementById('header-submenu').children;

        for(var i=0; i < submenu.length; i++)
        {
            console.log(submenu[i]);
        }

        // Muestra Panel

        // Ejecuta carga
    }

    get currentBtn(){
        return this.constructor.currentBtn;
    }

    set currentBtn(i){
        this.constructor.currentBtn = i;
    }

    toggleMainMenu = function(){
        let MainMenu = document.getElementById('main-menu');
        let op = MainMenu.getAttribute('data-main-menu-status');
    
        if(op === 'closed')
        {
            this.openMainMenu();
        }
    
        if(op === 'open')
        {
            this.closeMainMenu();
        }
    }



    // Menu/Submenu
    // Visible
    // Transparent
    // Hidden


    // Callbacks

    postLogin = function(usr, pwd){}
}
*/

export var StyleComponent = function(){
    var _st = {};
    function init(inputs){
        _st.imagePath = 'https://app.onelogis.com/images/alerts/';
    };

    function getStyle(data){
        return _getIcon(data);
    };

    function _getIcon(props){
        var ret = null;
        let e = props.event;
        
        switch(e)
        {
             case 'ENGINE_ON':
                 ret = {
                    iconUrl: _st.imagePath + 'se_s.png',
                    imageUrl: _st.imagePath + 'se_b.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-ok"
                };
            break;

            case 'ENGINE_OFF':
               ret = {
                    iconUrl: _st.imagePath + 'ste_s.png',
                    imageUrl: _st.imagePath + 'ste_b.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-disable",
                };
            break;

            case 'PERIODIC_ON':
            ret = {
                    iconUrl: _st.imagePath + 'po_s.png',
                    imageUrl: _st.imagePath + 'po_b.png',
                    iconSize:     [26, 26], // size of the icon
                    iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -15], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation: parseInt(props.head),
                    panelStyle:"mon-panel-ok",
                };
                break;

            case 'PERIODIC_OFF':
                ret = {
                    iconUrl: _st.imagePath + 'pa_s.png',
                    imageUrl: _st.imagePath + 'pa_b.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [4,-12],
                    rotation:     0,
                    panelStyle:"mon-panel-disable",
                };

            break;

            case 'vENGINE_ON':
                 ret = {
                    iconUrl: _st.imagePath + 'se_s.png',
                    imageUrl: _st.imagePath + 'se_b.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-ok",
                };
            break;

            case 'vENGINE_OFF':
               ret = {
                    iconUrl: _st.imagePath + 'ste_s.png',
                    imageUrl: _st.imagePath + 'ste_b.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-disable",
                };
            break;

            case 'vPERIODIC_ON':
            ret = {
                    iconUrl: _st.imagePath + 'po_s.png',
                    imageUrl: _st.imagePath + 'po_b.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -15], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation: parseInt(props.head),
                    panelStyle:"mon-panel-ok",
            };
                break;

            case 'vPERIODIC_OFF':
                ret = {
                    iconUrl: _st.imagePath + 'pa_s.png',
                    imageUrl: _st.imagePath + 'pa_b.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [4,-12],
                    rotation:     0,
                    panelStyle:"mon-panel-disable",
                };

            break;

            case 'SPEEDING':
                ret = {
                    iconUrl: _st.imagePath + 'sp_s.png',
                    imageUrl: _st.imagePath + 'sp_b.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [4,-26],
                    rotation:     0,
                    panelStyle:"mon-panel-alert",
                };
                break;

            case 'HARSH_TURN':
                ret = {
                     iconUrl: _st.imagePath + 'po_s.png',
                     imageUrl: _st.imagePath + 'po_b.png',
                    iconSize:     [26, 26], // size of the icon
                    iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -15], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation: parseInt(props.head),
                    panelStyle:"mon-panel-alert",
                };
                break;

            case 'TURN':
                ret = {
                     iconUrl: _st.imagePath + 'po_s.png',
                     imageUrl: _st.imagePath + 'po_b.png',
                    iconSize:     [26, 26], // size of the icon
                    iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -15], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation: parseInt(props.head),
                    panelStyle:"mon-panel-ok",
                };
                break;

            case 'HARSH_DECEL':
                ret = {
                    iconUrl: _st.imagePath + 'hd_s.png',
                    imageUrl: _st.imagePath + 'hd_b.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-alarm",
                };
                break;

            case 'HARSH_ACEL':
                ret = {
                    iconUrl: _st.imagePath + 'ha_s.png',
                    imageUrl: _st.imagePath + 'ha_b.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-alarm",
                };
                break;

            case 'IDLE':
            ret = {
                    iconUrl: _st.imagePath + 'id_s.png',
                    imageUrl: _st.imagePath + 'id_b.png',
                    iconSize:     [32, 32], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0,
                    panelStyle:"mon-panel-alert",
                };
                break;
            case 'TRIP_START':
            ret = {
                    iconUrl: _st.imagePath + 'ts_s.png',
                    imageUrl: _st.imagePath + 'ts_b.png',
                    iconSize:     [20, 20], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0
                };
                break;
            case 'TRIP_STOP':
            ret = {
                    iconUrl: _st.imagePath + 'tsp_s.png',
                    imageUrl: _st.imagePath + 'tsp_b.png',
                    iconSize:     [20, 20], // size of the icon
                    iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation:     0
                };
                break;
            case 'GEOFENCE_IN':
                ret = {
                        iconUrl: _st.imagePath + 'gfi_s.png',
                        imageUrl: _st.imagePath + 'gfi_b.png',
                        iconSize:     [32, 32], // size of the icon
                        iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                        popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                        labelAnchor:  [3,0],
                        rotation:     0
                    };
                break;
            case 'GEOFENCE_OUT':
                ret = {
                        iconUrl: _st.imagePath + 'gfo_s.png',
                        imageUrl: _st.imagePath + 'gfo_b.png',
                        iconSize:     [32, 32], // size of the icon
                        iconAnchor:   [19, 19], // point of the icon which will correspond to marker's location
                        popupAnchor:  [0, -16], // point from which the popup should open relative to the iconAnchor
                        labelAnchor:  [3,0],
                        rotation:     0
                    };
                break;
            default:

                ret = {
                    iconUrl: _st.imagePath + 'po_s.png',
                    imageUrl: _st.imagePath + 'po_s.png',
                    iconSize:     [26, 26], // size of the icon
                    iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
                    popupAnchor:  [0, -15], // point from which the popup should open relative to the iconAnchor
                    labelAnchor:  [3,0],
                    rotation: parseInt(props.head),
                    panelStyle:"mon-panel-ok",
                };
            break;

        }
        return ret;
    };

    return {init:init, getStyle:getStyle};
}();



export var AssetTripListComponent = function(){
    var cur = {id: null, vehicle: null};

    function init(inputs){
        cur.eventClick = inputs.eventClick;
       

        let at = document.getElementById('rp-trips-list');
        at.addEventListener('transitionend', () => {

        });
        
        $('#asset-trip__date').pickadate({
            // Strings and translations
            monthsFull: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Deciembre'],
            monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            weekdaysFull: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
            weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            today: 'Hoy',
            clear: '',
            close: 'Cerrar',
            format: 'dddd, d mmmm yyyy',
            closeOnSelect: true,
            formatSubmit: 'yyyy-mm-dd',
            hiddenPrefix: 'asset-trip__date_'
                   });

        let _date_picker = document.getElementById('asset-trip__date');
        _date_picker.onchange = function(e){
            let _date = document.getElementsByName('asset-trip__date__submit')[0].value;
            cur.eventClick('TRIP_LIST_UPDATE', {date: _date, id:cur.id});
            setElementLoading("rp-trips-list");
        }
    };

    function show(inputs){

        cur.id = inputs.id;
        cur.vehicle = inputs.vehicle;

        let at = document.getElementById('asset-trip-page');
        at.style.visibility = 'visible';
        let move = window.innerWidth;
        at.style.width = '100%';
        at.style.transform='translateX(-' + move + 'px)';
    
        at = document.getElementById('rp-trips-list');
        at.style.height = (window.innerHeight - 110) + 'px';
        document.getElementById("asset-trip__txt-header").innerHTML = inputs.vehicle.plate + ' - '+ inputs.vehicle.alias;

        document.getElementById("rp-trips-list").innerHTML = "";
        document.getElementById("rp-trips-list").innerHTML = composeDefaultScreen();
        document.getElementById("asset-trip__date").value = "";

    };

    function render(data){

        var lst = document.getElementById("rp-trips-list");
        lst.innerHTML = '';

        if(data.length == 0){
            document.getElementById("rp-trips-list").innerHTML =  composeEmptyReport(data);
        }

        for(let i=0; i<data.length; i++){
            lst.innerHTML += composeBox(data[i]);
        }
        registerBoxTap();
    };

    function composeDefaultScreen(){
        let ret = `<div class="asset-trip-list__empty">
                        <h4 class="asset-trip-list__empty_text">Selecciona la fecha a consultar</h4>
                    </div>`;
        return ret;
    };

    function composeEmptyReport(data){
        let ret = `<div class="asset-trip-list__empty">
                        <h4 class="asset-trip-list__empty_text">Sin viajes para mostrar</h4>
                    </div>`;
        return ret;
    };

    function composeBox(row){
        let ret = '';
        let stime = dateToHM(new Date(row.start_time));
        let etime = dateToHM(new Date(row.end_time));
        let distance =  Math.round(parseInt(row.distance));
        if(row.type == 't'){
            ret = `
            <div id="t-${row.trip_id}" data-trip="${row.trip_id}" class="ad-tbox ad-cd-row-line waves-effect alert-box-selected ad-tbox-selected asset-trip-box">                        
                <div class="ad-trip-icon ad-trip-icon-closed"></div>                        
                <div class="ad-trip-detail">                            
                    <div class="ad-trip-tend">                                
                    <div class="ad-trip-taddr" title="${row.end_address.address}">${row.end_address.address}</div>                                
                    <div class="ad-trip-time">${etime}</div>                            
                </div>                            
                <div class="ad-trip-tstart">                                
                    <div class="ad-trip-taddr" title="${row.start_address.address}">${row.start_address.address}</div>                                
                    <div class="ad-trip-time">${stime}</div>                            
                </div>                        
                </div>                        
                <div class="ad-trip-trip">                            
                    <div class="ad-trip-km">${distance}  km</div>                            
                    <div class="ad-trip-elapsed">${row.elapsed_time}</div>                            
                </div>                    
            </div>`;
        }
        else{
            ret = `
            <div id="t-${row.trip_id}" data-trip="${row.trip_id}" class="ad-tbox ad-cd-row-line waves-effect asset-trip-box">
                <div class="ad-trip-icon ad-trip-icon-stop"></div>
                <div class="ad-trip-detail">
                <div class="ad-trip-tstop" title="${row.end_address.address}">${row.end_address.address}</div>
                    <div class="ad-trip-tstop-time">                                
                        <div class="ad-trip-time">${etime}</div>                                
                        <div class="ad-trip-time">${stime}</div>                            
                    </div>                        
                </div>                        
                <div class="ad-trip-trip">                            
                    <div class="ad-trip-elapsed ad-trip-elapsed-stop">${row.elapsed_time}</div>                            
                </div>                    
            </div>`;
        }
        return ret;
    };

    function registerBoxTap(){
        let vList = document.getElementsByClassName('asset-trip-box');

          for(let i=0; i<vList.length; i++){
              let vId = vList.item(i).getAttribute('data-trip');
              let hammerList = new Hammer(vList.item(i));
              
              hammerList.on("tap", function(ev){
                
                let dv =  ev.target.closest(".ad-tbox");
                let _trip_id = dv.getAttribute('data-trip');
                
                cur.eventClick('TRIP_DETAIL', {trip_id : _trip_id, vehicle : cur.vehicle});

              });
          }
    }

    function hide(){
        let Page = document.getElementById('asset-trip-page');
        let move = window.innerWidth;
        Page.style.transform='translateX('+ move +'px)';
        
    };

    return {init:init, render:render, hide:hide, show:show};

}();
export var SuspendedPage = function(){
    var c = {}
    
    function init(inputs){
        c.eventClick = inputs.eventClick;
    };

    function show(inputs){
        let at = document.getElementById('suspended-page'); 
        at.style.visibility = 'visible';
        let move = window.innerWidth;
        at.style.width = '100%';
        at.style.height = window.innerHeight;
        at.style.transform = 'translateX(-' + move + 'px)';
    };

    function hide(){
        let at = document.getElementById('suspended-page');
        at.style.visibility = 'hidden';
        at.style.transform='translateX(500px)';
    };

    function render(data){
        document.getElementById("page-suspended__text").innerHTML = data.message;
        show({});
    };


    return {init:init, show:show, hide:hide, render:render};
}();

import {MapUtilsComponent} from '../../MapUtils/v1.0/maputils.js';


export var AssetListComponent = function(){
    var al = {};

    function init(inputs){
        al.tabHeight = 50;
        al.height = inputs.height;
        al.translate = al.height - al.tabHeight;
        al.eventClick = inputs.eventClick;
        MapUtilsComponent.init();

        document.getElementById('asset-list').style.top = 0;
        document.getElementById('asset-list').style.height = inputs.height;
        document.getElementById('asset-list').style.transform=`translateY(${al.translate}px)`;
        document.getElementById('asset-list').classList.add("round-small");
        
        document.getElementById('asset-list-tab__dash').style.left = (inputs.width/2 -16)+'px';;
        document.getElementById('asset-list-tab-title').style.left = (inputs.width/2 -65)+'px';;
        hideActionButton();

        //myElement.style.height = window.height;
        var tab = document.getElementById('asset-list-tab');
        var hammerTab = new Hammer(tab);
        hammerTab.get('pan').set({ direction: Hammer.DIRECTION_ALL });

        var direction = "";
       
        
        hammerTab.on('pan', function(ev){

            let FleetOptions = document.getElementById('asset-list');
            let posY = ev.center.y;

            if(ev.center.y >= (al.translate * 0.85)){
                FleetOptions.classList.add('global-transition-effect');
                document.getElementById('asset-list').classList.add("round-small");
                document.getElementById('asset-list-tab__dash').style.visibility = "hidden";
                document.getElementById('asset-list-tab-title').style.visibility = "hidden";
                posY = al.translate;
                hideActionButton();
            }
      
            if(ev.center.y < (al.translate * 0.1))
            {
                FleetOptions.classList.add('global-transition-effect');
                document.getElementById('asset-list').classList.remove("round-small");
                document.getElementById('asset-list-tab__dash').style.visibility = "visible";
                document.getElementById('asset-list-tab-title').style.visibility = "visible";
                posY = 0;
                showActionButton();
            }
            
            FleetOptions.classList.remove('global-transition-effect');
            FleetOptions.style.transform='translateY(' + posY + 'px)';

        });

    
        hammerTab.on('panstart', function(ev){
            //app.monitor.startCoord = ev.center.y;
        });

        hammerTab.on('panend', function(ev){
            let posY = 0;
            if(ev.center.y > (al.translate * 0.68)){
                document.getElementById('asset-list').classList.add('global-transition-effect');
                document.getElementById('asset-list').classList.add("round-small");
                posY = al.translate;
                hideActionButton();
                
            }
            else{
                document.getElementById('asset-list').classList.add('global-transition-effect');
                document.getElementById('asset-list').classList.remove("round-small");
                posY = 0;
                showActionButton();
            }
            document.getElementById('asset-list').style.transform='translateY(' + posY + 'px)';
        });


            // Header Action Button
            var aButton = document.getElementById('asset-list-tab-btn');
            var hammerMenu = new Hammer(aButton);
            hammerMenu.on('tap', function(ev) {
               hide();
            });
    };

    function hideActionButton(){
        document.getElementById('asset-list-tab-btn').style.visibility = 'hidden'
        document.getElementById('asset-list-tab__dash').style.visibility = "visible";
        document.getElementById('asset-list-tab-title').style.visibility = "visible";
        
    };

    function showActionButton(){
        document.getElementById('asset-list-tab-btn').style.visibility = 'visible'
        document.getElementById('asset-list-tab__dash').style.visibility = "hidden";
        document.getElementById('asset-list-tab-title').style.visibility = "hidden";
    };

    function render(_d){
        var lst = document.getElementById("mon-vehicle-list");
        lst.innerHTML = ''; // Clear list
        let _l = _d.features;
        
        for(var i in _l){
            var newBox = createBox(_l[i]);
            lst.innerHTML += newBox;
        }

        // Registra eventos para cajas
        registerBoxTap(_d);
        document.getElementById('asset-list').style.display = "block";

         // Registrar clicks
        document.getElementById("btn-search-del").onclick = function(e){  
            document.getElementById("txt-search-asset").value = "";
            document.getElementById("txt-search-asset").focus();
            searchInList("");
        }

        document.getElementById('txt-search-asset').onkeyup = function(e){
            let _txt = document.getElementById('txt-search-asset').value;
            searchInList(_txt);
        }
        

        let _elements_count = _l.length;
        let _str_title = (_elements_count > 1)?`Mostrando ${_l.length} activos` : `Mostrando ${_l.length} activo`;
        document.getElementById('asset-list-tab-title').innerHTML = _str_title;

    };

    function searchInList(input){
        var query = input.toLowerCase();

        let _boxes = document.getElementsByClassName('mon-vehicle-box');

        for(let i=0; i< _boxes.length; i++){
            if(_boxes[i].textContent.toLocaleLowerCase().indexOf(query)>=0){
                _boxes[i].style.display = "block";
            }
            else{
                _boxes[i].style.display = "none";
            }
        }
    };

    function createBox(_d){
       
        var newDiv = null;
        let o = _d.properties;

        let _ico = MapUtilsComponent.getIcon({event:_d.properties.event, head:0});
  
        newDiv = '<div id="l-' + o.vehicle_id + '" data-id ="'+ o.vehicle_id+ '" dLat ="'+_d.geometry.coordinates[1]+'" class="mon-vehicle-box waves-effect ' +  _ico.panelStyle + '">';
        newDiv += createBoxContent(_d);
        newDiv += '</div>';
        return newDiv;
    };

    function createBoxContent(_d){
        
        var newDiv = null;
    
        let o = _d.properties;

        newDiv = '\
            <div class="mon-vehicle-box-speed" title="Velocidad actual"><div class="mon-vehicle-box-speed-val">'+ o.speed +'</div><small>km/h</small></div>\
            <div class="mon-vehicle-box-description">\
                    <div class="mon-vehicle-box-title">\
                        <div class="mon-vehicle-box-title-plate">' + o.plate + '</div>\
                        <div class="mon-vehicle-box-title-driver">'+ o.alias +'</div>\
                    </div>\
                    <div class="mon-vehicle-box-state">'+ o.event_name +'</div>\
                    <div class="mon-vehicle-box-detail"><span class="mdi mdi-target mon-vehicle-box-icon" title="Dirección"></span>'+ o.address +'</div>\
                    <div class="mon-vehicle-box-detail mon-vehicle-box-update" title="'+ o.device_time + '"><span class="mdi mdi-clock-time-seven-outline mon-vehicle-box-icon" title="Fecha de reporte"></span>'+ o.device_time + '</div><p>\
                    <div class="mon-vehicle-box-detail mon-vehicle-box-journey"><span class="mdi mdi-road-variant mon-vehicle-box-icon" title="Viaje actual"></span>' + o.elapsed_time +'  - ' + o.distance +' km</div>\
                </div>\
            ';
        return newDiv;
    };

    function registerBoxTap(d){
          // Iterate over list
          let vList = document.getElementsByClassName('mon-vehicle-box');
          for(let i=0; i<vList.length; i++)
          {
              let vId = vList.item(i).getAttribute('data-id');
              let hammerList = new Hammer(vList.item(i));
              hammerList.on("tap", function(ev){
                  let dv = ev.target.closest(".mon-vehicle-box");
                  let id = dv.getAttribute('data-id');
                 
                  al.eventClick("ASSET_DETAIL", {id: id})
              });
          }
    };

    function hide(){
        document.getElementById('asset-list').classList.add('global-transition-effect');
        document.getElementById('asset-list').classList.add("round-small");
        document.getElementById('asset-list').style.transform=`translateY(${al.translate}px)`;
        hideActionButton();
    };

    return {init: init, render: render, hide:hide};
}();
